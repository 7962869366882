import {unixTime} from './dateFunctions'
import TokenParser from "./TokenParser";

const TokenStorage = {
  get: () => {
    const token = localStorage.getItem('carbon-token')
    let expiration = localStorage.getItem('carbon-token-expiry')
    const now = unixTime()

    expiration = expiration === null ? 0 : parseInt(expiration, 10)

    if (token === null) {
      return null
    }

    if (expiration < now) {
      TokenStorage.remove()
      return null
    }

    return token
  },
  set: (token) => {
    const parsed = new TokenParser(token)
    localStorage.setItem('carbon-token', token)
    localStorage.setItem('carbon-token-expiry', parsed.getClaim('exp'))
  },
  remove: () => {
    localStorage.removeItem('carbon-token')
    localStorage.removeItem('carbon-token-expiry')
  }
}

export default TokenStorage
