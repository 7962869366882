/**
 *
 * @param number
 * @param decimals
 * @returns {number}
 */
const roundDown = (number = 0, decimals = 2) => {
  decimals = decimals || 0;
  return (Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}


export {roundDown}
