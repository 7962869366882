<template>
  <footer class="bg-info py-5 d-none">
    <b-container>
      <b-row>
        <b-col md="4">
          <h3>Title</h3>
          <p>Lorem ipsum dolor sin amet lorem ipsum dolor sin amet lorem ipsum dolor sin amet. Lorem ipsum dolor sin
            amet lorem.</p>
        </b-col>
        <b-col md="4">
          <h3>Title</h3>
          <p>Lorem ipsum dolor sin amet lorem ipsum dolor sin amet lorem ipsum dolor sin amet. Lorem ipsum dolor sin
            amet lorem.</p>
        </b-col>
        <b-col md="4">
          <h3>Title</h3>
          <p>Lorem ipsum dolor sin amet lorem ipsum dolor sin amet lorem ipsum dolor sin amet. Lorem ipsum dolor sin
            amet lorem.</p>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
