import {unixTime} from './dateFunctions'

/**
 * @param token
 * @returns {*}
 */
const parseToken = (token) => {
  let tokenArr = token.split('.').slice(0, 2)
  tokenArr = tokenArr.map((x) => {
    x = x.replace(/-/g, '+').replace(/_/g, '/')
    x = decodeURIComponent(atob(x).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(x)
  })

  return tokenArr
}

/**
 * TokenParser class
 */
class TokenParser {
  constructor(token) {
    let header, payload
    [header, payload] = parseToken(token)
    this.token = token
    this.header = header || null
    this.payload = payload || null
  }

  get issuedAt() {
    return this.getClaim('iat')
  }

  get expiresAt() {
    return this.getClaim('exp')
  }

  get userId() {
    return this.getClaim('id')
  }

  getHeader(name) {
    if (this.header === null) {
      return null
    }
    return this.header[name]
  }

  getClaim(name) {
    if (this.payload === null) {
      return null
    }
    return this.payload[name]
  }

  isExpired() {
    const now = unixTime()

    if (this.expiresAt === null) {
      return true
    }

    return now > this.expiresAt
  }

  isLogged() {
    let isLogged = false

    if ('payload' in this) {
      isLogged = !('anonymous' in this.payload && this.payload.anonymous)
    }

    return isLogged
  }
}

export default TokenParser
