import Vue from 'vue'
import router from './router'
import Toasted from 'vue-toasted'
import App from './App.vue'
import store from './store'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'mapbox-gl/dist/mapbox-gl.css'
import '@fortawesome/fontawesome-free/css/all.css'
// import 'chartjs-plugin-annotation/chartjs-plugin-annotation.min.js'
// import 'bootstrap/dist/css/bootstrap.css'
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css'
import 'datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.min.css'
import 'datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css'
import 'datatables.net-scroller-bs4/css/scroller.bootstrap4.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(Toasted)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
