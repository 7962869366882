import Vue from 'vue';
import Vuex from 'vuex';
import user from "./modules/user";
import accounts from "./modules/accounts";
import contracts from "./modules/contracts";
import NFTcontract from "./modules/NFTcontract";
import tokenContract from "./modules/tokenContract";
import lockedTokenContract from "./modules/lockedTokenContract";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    accounts,
    contracts,
    NFTcontract,
    tokenContract,
    lockedTokenContract
  }
});
