const Header = {

  Types: {
    AUTHORIZATION: 'Authorization',
  },

  Values: {
    HEADER_OBJ_KEY: 'headers',
    BEARER: 'Bearer '
  },

  /**
   * @param token
   * @returns {string}
   */
  getTokenValue(token) {
    return Header.Values.BEARER + token
  }
}

export default Header
