import axios from 'axios'
import TokenStorage from '../utils/TokenStorage'
import Header from '../utils/Header'


const ajaxRequest = (config) => {


  const token = TokenStorage.get()
  config.baseURL = process.env.VUE_APP_API_URL
  config.headers = config.headers || {}
  if (token) {
    config.headers[Header.Types.AUTHORIZATION] = Header.getTokenValue(token)
  }

  return axios.request(config).then(response => {
    return response
  })
}

const Ajax = {
  get: (endpoint, config = {}) => {
    config = {
      ...config,
      ...{
        url: endpoint,
        method: 'GET'
      }
    }

    return ajaxRequest(config)
  },

  post: (endpoint, params, config = {}) => {
    config = {
      ...config,
      ...{
        url: endpoint,
        method: 'POST',
        data: params
      }
    }

    return ajaxRequest(config)

  },

  put: (endpoint, params, config = {}) => {
    config = {
      ...config,
      ...{
        url: endpoint,
        method: 'PUT',
        data: params
      }
    }

    return ajaxRequest(config)
  },

  delete: (endpoint, params = null, config = {}) => {
    config = {
      ...config,
      ...{
        url: endpoint,
        method: 'DELETE'
      }
    }

    if (params) {
      config.data = params
    }

    return ajaxRequest(config)
  }
}

export default Ajax
