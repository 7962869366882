import nftContractAbi from "../../contracts/NFTcontractAbi.json";
import {ethers} from "ethers";
import Config from "../../api/Config";
import Area from "../../api/Area";

const state = {
  nftTx: null,
  calcAbi: null,
  calcAddress: null,
  calcContractAddress: null,
  calcContract: null,
  signer: null
};

const getters = {
  getNftTx(state) {
    return state.nftTx;
  },
  getNftAbi(state) {
    return state.calcAbi;
  },
  getNftAddress(state) {
    return state.calcAddress;
  },
  getNftContract(state) {
    return state.calcContract;
  },
  getSigner(state) {
    return state.calcSigner;
  }
};

const actions = {
  async fetchNftContract({commit}, privateKey) {
    // console.log({rootState})
    // let providerW3m = rootState.accounts.providerW3m;
    // const provider = new ethers.providers.Web3Provider(providerW3m);

    const config = (await Config.getConfig()).data;
    const provider = new ethers.providers.JsonRpcProvider(config.json_rpc_provider);
    let contractAddress = config.contract_address;
    const signer = new ethers.Wallet(privateKey, provider);
    const contract = new ethers.Contract(
      contractAddress,
      nftContractAbi,
      signer
    );

    commit("setNftContract", contract);
    commit("setNftContractAddress", contractAddress);
    commit("setSigner", signer);
  },
  async createNftToken({commit, state}, data) {
    const config = (await Config.getConfig()).data;

    const hasRole = await state.calcContract.hasRole('0x9f2df0fed2c77648de5860a4cc508cd0818c85b8b8a1ab4ceeef8d981c8956a6', data.walletAddress);

    if (!hasRole) {
      await Area.allowWallet(data.walletAddress);

    }


    const totalSupply = await state.calcContract.biggestTokenIdNum();
    const nextTokenId = totalSupply.toNumber() + 1;


    let tx = await state.calcContract.mint(
      data.walletAddress,
      nextTokenId,
      data.scanDt,
      [
        ethers.utils.parseUnits(data.tonCO2Year1.toString()),
        ethers.utils.parseUnits(data.tonCO2Year2.toString()),
        ethers.utils.parseUnits(data.tonCO2Year3.toString()),
        ethers.utils.parseUnits(data.tonCO2Year4.toString()),
        ethers.utils.parseUnits(data.tonCO2Year5.toString()),
        ethers.utils.parseUnits(data.tonCO2Year6.toString()),
        ethers.utils.parseUnits(data.tonCO2Year7.toString()),
        ethers.utils.parseUnits(data.tonCO2Year8.toString()),
        ethers.utils.parseUnits(data.tonCO2Year9.toString()),
        ethers.utils.parseUnits(data.tonCO2Year10.toString())
      ]
    )


    await tx.wait();

    commit("setNftTx", tx);

    return {
      contractAddress: state.calcContractAddress,
      tokenId: nextTokenId,
      tokenIdUrl: config.token_id_url_template.replace(/\{contractAddress}/g, state.calcContractAddress).replace(/\{tokenId}/g, nextTokenId),
      chain: config.chain,
      tx: tx.hash,
      txUrl: config.tx_url_template.replace(/\{tx}/g, tx.hash)
    }
  },
  async deleteNftToken({commit, state}, tokenId) {
    if (!state.calcContract) {
      this.fetchNftContract();
    }
    const config = (await Config.getConfig()).data;

    let tx = await state.calcContract.burn(tokenId)
    await tx.wait();


    commit("setNftTx", tx);

    return {
      contractAddress: state.calcContractAddress,
      tokenId,
      tokenIdUrl: config.token_id_url_template.replace(/\{contractAddress}/g, state.calcContractAddress).replace(/\{tokenId}/g, tokenId),
      chain: config.chain,
      tx: tx.hash,
      txUrl: config.tx_url_template.replace(/\{tx}/g, tx.hash)
    }
  }
};

const mutations = {
  setNftTx(state, _nftTx) {
    state.nftTx = _nftTx;
  },
  setNftAbi(state, abi) {
    state.calcAbi = abi;
  },
  setNftAddress(state, address) {
    state.calcAddress = address;
  },
  setNftContract(state, _contract) {
    state.calcContract = _contract;
  },
  setNftContractAddress(state, _contractAddress) {
    state.calcContractAddress = _contractAddress;
  },
  setSigner(state, _signer) {
    state.calcSigner = _signer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
