import Ajax from './Ajax'

const User = {
  getMe: () => {
    const url = '/users/me'

    return Ajax.get(url)
  },
  getUsers: () => {
    return Ajax.get('/users')
  },
  putMe: (params) => {
    return Ajax.put(`/users/me`, params)
  },
  putUser: (params) => {
    return Ajax.put(`/users/${params.id}`, params)
  },
  changePassword: (params) => {
    const url = '/changePassword'

    return Ajax.put(url, params)
  },

  changeUsername: (params) => {
    const url = '/changeUsername'

    return Ajax.put(url, params)
  }
}

export default User
