import Ajax from './Ajax.js'

const Area = {
  getAreasPrivateKey: (id) => {
    return Ajax.get(`/areas/${id}/private-key`);
  },
  allowWallet: (walletAddress) => {
    return Ajax.post(`/areas/${walletAddress}/allow-wallet`)
  },
  claim: (id) => {
    return Ajax.post(`/areas/${id}/claim`)
  },
  getClaimable: (id) => {
    return Ajax.get(`/areas/${id}/claim`)
  },
  getBalance: (id) => {
    return Ajax.get(`/areas/${id}/balance`)
  },
  withdraw: (id, data) => {
    return Ajax.post(`/areas/${id}/withdraw/${data.walletAddress}`, {amount: data.amount})
  },
  getAreas: (id = null, userId) => {
    let uri = '/areas';
    if (id !== null) {
      uri += '/' + id;
    } else if (userId) {
      uri += `?users_permissions_user.id_eq=${userId}`;
    }
    return Ajax.get(uri);
  },
  getAreaInfo: (id = null) => {
    return Ajax.get(`/areas/${id}/info`);
  },
  getAreasMap: () => {
    let uri = '/areas/map';
    return Ajax.get(uri);
  },
  getAreasDatatables: () => {
    let uri = '/areas-datatables';
    return Ajax.get(uri);
  },
  postAreas: (params) => {
    return Ajax.post('/areas', params)
  },
  putAreas: (params) => {
    return Ajax.put('/areas/' + params.id, params)
  },
  delAreas: (id) => {
    return Ajax.delete('/areas/' + id)
  }
}

export default Area
