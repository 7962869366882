import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        title: 'Home'
      },
      component: () => import('../views/Home')
    },
    {
      path: '/admin',
      name: 'Admin',
      meta: {
        title: 'Admin'
      },
      component: () => import('../views/Admin')
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        title: 'Profile'
      },
      component: () => import('../views/Profile')
    },
    {
      path: '/retire-tokens',
      name: 'Retire Tokens',
      meta: {
        title: 'Retire tokens'
      },
      component: () => import('../views/LockTokens')
    },
    {
      path: '/areas/:id',
      name: 'Area Item',
      meta: {
        title: 'Area Item'
      },
      component: () => import('../views/Areas/AreaItem'),
    },
    {
      path: '/areas',
      redirect: '/areas-map'
    },
    {
      path: '/areas-list',
      name: 'Areas list',
      meta: {
        title: 'Areas list'
      },
      component: () => import('../views/Areas/AreasList'),
    },
    {
      path: '/areas-map',
      name: 'Areas map',
      meta: {
        title: 'Areas map'
      },
      component: () => import('../views/Areas/AreasMap'),
    },
    {
      path: '/tree-types/:id',
      name: 'Tree types form',
      meta: {
        title: 'Tree type form'
      },
      component: () => import('../views/TreeTypes/TreeTypeItem'),
    },
    {
      path: '/tree-types',
      name: 'Tree types',
      meta: {
        title: 'Tree types'
      },
      component: () => import('../views/TreeTypes'),
    },
    {
      path: '/customers',
      name: 'Customers',
      meta: {
        title: 'Customers'
      },
      component: () => import('../views/Customers'),
    },
    {
      path: '/customers/:id',
      name: 'Customers',
      meta: {
        title: 'Customer form'
      },
      component: () => import('../components/Customer/CustomerFormComponent'),
    },
    {
      path: '/Dashboard',
      name: 'Dashboard',
      meta: {
        title: 'Dashboard'
      },
      component: () => import('../views/Dashboard')
    },
    {
      path: '/trees',
      name: 'Trees',
      meta: {
        title: 'Trees'
      },
      component: () => import('../views/Trees')
    },
    {
      path: '*',
      meta: {
        title: 'Error 404',
        error: 404
      },
      component: () => import('../views/Error')
    }
  ]
})
