import Web3Modal from "web3modal";
import Web3 from "web3";
import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";
import Authereum from "authereum";
import WalletConnect from "@walletconnect/web3-provider";
import Swal from "sweetalert2";

const state = {
  activeAccount: null,
  activeBalance: 0,
  chainId: null,
  chainName: null,
  web3: null,
  isConnected: false,
  providerW3m: null, // this is "provider" from Web3Modal
  web3Modal: null
};

const getters = {
  getActiveAccount(state) {
    // if (!state.activeAccount) {
    //     return window.ethereum.selectedAddress;
    // }

    return state.activeAccount;
  },
  getActiveBalanceWei(state) {
    return state.activeBalance;
  },
  getActiveBalanceEth(state) {
    return state.web3.utils.fromWei(String(state.activeBalance), "ether");
  },
  getChainId(state) {
    return state.chainId;
  },
  getChainName(state) {
    return state.chainName;
  },
  getWeb3(state) {
    if (state.web3) {
      return state.web3;
    } else {
      return new Web3(Web3.givenProvider);
    }
  },
  getWeb3Modal(state) {
    return state.web3Modal;
  },
  isUserConnected(state) {
    return state.isConnected;
  }
};

const actions = {

  async initWeb3Modal({dispatch, commit}) {

    const providerOptions = {
      // MetaMask is enabled by default
      // Find other providers here: https://github.com/Web3Modal/web3modal/tree/master/docs/providers
      burnerconnect: {
        package: BurnerConnectProvider // required
      },
      authereum: {
        package: Authereum // required
      },
      walletconnect: {
        package: WalletConnect // required
      }
    };

    const w3mObject = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions // required
    });

    commit("setWeb3ModalInstance", w3mObject);

    // This will get deprecated soon. Setting it to false removes a warning from the console.
    window.ethereum.autoRefreshOnNetworkChange = false;

    // if the user is flagged as already connected, automatically connect back to Web3Modal
    if (localStorage.getItem('isWeb3Connected') === "true") {
      if (!actions.validChain({commit})) {
        return;
      }

      let providerW3m = await w3mObject.connect();
      commit("setIsConnected", true);

      commit("setActiveAccount", window.ethereum.selectedAddress);
      commit("setChainData", window.ethereum.chainId);
      commit("setWeb3Provider", providerW3m);

      dispatch('tokenContract/fetchTokenContract', {}, {root: true})
      dispatch('lockedTokenContract/fetchLockedTokenContract', {}, {root: true})
      actions.fetchActiveBalance({commit});


    }


  },

  async connectWeb3Modal({dispatch, commit}) {


    if (!actions.validChain({commit})) {
      return;
    }
    let providerW3m = await state.web3Modal.connect();
    commit("setIsConnected", true);

    commit("setActiveAccount", window.ethereum.selectedAddress);
    commit("setChainData", window.ethereum.chainId);
    commit("setWeb3Provider", providerW3m);

    dispatch('tokenContract/fetchTokenContract', {}, {root: true})
    dispatch('lockedTokenContract/fetchLockedTokenContract', {}, {root: true})
    actions.fetchActiveBalance({commit});

  },
  async disconnectWeb3Modal({commit}) {
    commit("disconnectWallet");
    commit("setIsConnected", false);
  },
  validChain({commit}) {
    console.log(process.env.VUE_APP_CHAIN_ID, 'process.env.VUE_APP_CHAIN_ID')
    console.log(window.ethereum.chainId, 'window.ethereum.chainId')
    console.log(window.ethereum, 'window.ethereum')
    if (window.ethereum && window.ethereum.chainId != null && window.ethereum.chainId != process.env.VUE_APP_CHAIN_ID) {
      Swal.fire({
        title: 'Wrong chain',
        html: `We only support ${process.env.VUE_APP_CHAIN} chain.<br>Please change your chain.`,
        showCancelButton: true,
        confirmButtonText: 'Change chain',
        cancelButtonText: `Close`,

      }).then((result) => {
        if (result.isConfirmed) {

          try {
            window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId: process.env.VUE_APP_CHAIN_ID,
                chainName: process.env.VUE_APP_CHAIN,
                rpcUrls: [
                  process.env.VUE_APP_CHAIN_RPC,
                ],
                nativeCurrency: {
                  name: 'Matic',
                  symbol: 'MATIC',
                  decimals: 18
                }
              }]
            });
          } catch (e) {
            console.log({e})
          }

        }
      })
      actions.disconnectWeb3Modal({commit})
      return false;
    } else {
      return true;
    }
  },

  async ethereumListener({dispatch, commit}) {

    window.ethereum.on('accountsChanged', (accounts) => {
      if (state.isConnected) {
        commit("setActiveAccount", accounts[0]);
        commit("setWeb3Provider", state.providerW3m);
        dispatch('tokenContract/fetchTokenContract', {}, {root: true})
        dispatch('lockedTokenContract/fetchLockedTokenContract', {}, {root: true})
        actions.fetchActiveBalance({commit});
      }
    });

    window.ethereum.on('chainChanged', async (chainId) => {
      if (!actions.validChain({commit})) {
        return;
      }
      if (!state.providerW3m) {
        // commit("connectWeb3Modal");
        await actions.connectWeb3Modal({dispatch, commit});
        let providerW3m = await state.web3Modal.connect();
        commit("setIsConnected", true);

        commit("setActiveAccount", window.ethereum.selectedAddress);
        commit("setChainData", window.ethereum.chainId);
        commit("setWeb3Provider", providerW3m);

        dispatch('tokenContract/fetchTokenContract', {}, {root: true})
        dispatch('lockedTokenContract/fetchLockedTokenContract', {}, {root: true})
        actions.fetchActiveBalance({commit});
        return;
      }

      commit("setChainData", chainId);
      commit("setWeb3Provider", state.providerW3m);
      dispatch('tokenContract/fetchTokenContract', {}, {root: true})
      dispatch('lockedTokenContract/fetchLockedTokenContract', {}, {root: true})
      actions.fetchActiveBalance({commit});
    });

  },

  async fetchActiveBalance({commit}) {
    let balance = await state.web3.eth.getBalance(state.activeAccount);
    commit("setActiveBalance", balance);
  }

};

const mutations = {

  async disconnectWallet(state) {
    state.activeAccount = null;
    state.activeBalance = 0;
    state.web3 = null;
    if (state.providerW3m !== null && state.providerW3m.close) {
      await state.providerW3m.close();
    }
    state.providerW3m = null;
    await state.web3Modal.clearCachedProvider();

    // window.location.href = '../'; // redirect to the Main page
  },

  setActiveAccount(state, selectedAddress) {
    state.activeAccount = selectedAddress;
  },

  setActiveBalance(state, balance) {
    state.activeBalance = balance;
  },

  setChainData(state, chainId) {
    state.chainId = chainId;

    switch (chainId) {
      case "0x1":
        state.chainName = "Mainnet";
        break;
      case "0x2a":
        state.chainName = "Kovan";
        break;
      case "0x3":
        state.chainName = "Ropsten";
        break;
      case "0x4":
        state.chainName = "Rinkeby";
        break;
      case "0x5":
        state.chainName = "Goerli";
        break;
      case "0x54":
        state.chainName = "Polygon";
        break;
      case "0x539": // 1337 (often used on localhost)
      case "0x1691": // 5777 (default in Ganache)
      default:
        state.chainName = "Localhost";
        break;
    }
  },

  async setWeb3Provider(state, providerW3m) {
    state.providerW3m = providerW3m;
    state.web3 = new Web3(providerW3m);
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
    // add to persistent storage so that the user can be logged back in when revisiting website
    localStorage.setItem('isWeb3Connected', isConnected);
  },

  setWeb3ModalInstance(state, w3mObject) {
    state.web3Modal = w3mObject;
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
