import Ajax from './Ajax'

const Login = {

  postLogin: (token, params) => {

    return Ajax.post('/auth/local', params, {
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': token
      }
    })
  },

  renewToken: (params) => {
    return Ajax.put('/token', params)
  },

  postRegister: (token, params) => {
    return Ajax.post('/auth/local/register', params, {
      headers: {
        'Content-Type': 'application/json',
        'X-Recaptcha-Token': token
      }
    })
  },
}

export default Login
