<template>
  <div id="app">
    <Header/>

    <router-view></router-view>

    <Footer/>
  </div>
</template>

<script>
import Header from './components/structure/Header'
import Footer from './components/structure/Footer'

export default {
  name: 'App',

  components: {
    Header,
    Footer
  },

  created() {
    this.$store.dispatch('user/initWeb2')
    this.$store.dispatch('accounts/initWeb3Modal')
    this.$store.dispatch('accounts/ethereumListener')

  }
}
</script>

<style lang="scss">
@import 'assets/scss/app.scss';

#app {
  p, ul {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
