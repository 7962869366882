import lockedTokenContractAbi from "../../contracts/lockedTokenContractAbi.json";
import {ethers} from "ethers";
import Config from "../../api/Config";

const state = {
  lockedTokenTx: null,
  lockedTokenBalance: null,
  calcAbi: null,
  calcAddress: null,
  calcContractAddress: null,
  calcContract: null,
  signer: null
};

const getters = {
  getLockedTokenTx(state) {
    return state.lockedTokenTx;
  },
  getLockedTokenBalance(state) {
    return state.lockedTokenBalance;
  },
  getLockedTokenAbi(state) {
    return state.calcAbi;
  },
  getLockedTokenAddress(state) {
    return state.calcAddress;
  },
  getLockedTokenContract(state) {
    return state.calcContract;
  },
  getSigner(state) {
    return state.calcSigner;
  }
};

const actions = {
  async fetchLockedTokenContract({dispatch, commit, rootState}) {

    let providerW3m = rootState.accounts.providerW3m;
    const provider = new ethers.providers.Web3Provider(providerW3m);
    const config = (await Config.getConfig()).data;
    // const provider = new ethers.providers.JsonRpcProvider(config.json_rpc_provider);
    let contractAddress = config.locked_token_contract_address;
    const signer = provider.getSigner();
    // const signer = new ethers.Wallet(privateKey, provider);
    const contract = new ethers.Contract(
      contractAddress,
      lockedTokenContractAbi,
      signer
    );

    commit("setLockedTokenContract", contract);
    commit("setLockedTokenContractAddress", contractAddress);
    commit("setSigner", signer);

    dispatch("updateLockedBalance");
  },
  async updateLockedBalance({commit, state}) {
    if (state.calcContract) {

      const balance = await state.calcContract.balanceOf(await state.calcSigner.getAddress());

      commit("setLockedTokenBalance", parseFloat(ethers.utils.formatEther(balance)));

      return {
        balance
      }
    }
  },
  async lockTokens({dispatch, state, rootState}, amount) {
    let tx;
    try {
      await actions.sleep(1);
      tx = await state.calcContract.retire(ethers.utils.parseEther(amount.toString()));
      await tx.wait();

      console.log({tx})
      return tx;

    } catch (e) {
      console.log({e})
      console.log('rootState.tokenContract.tokenBalance', rootState.tokenContract.tokenBalance)
      console.log(state.lockedTokenBalance, 'state.lockedTokenBalance')
      console.log(amount, 'amount')
      if (parseFloat(rootState.tokenContract.tokenBalance) < parseFloat(amount)) {
        throw `You don't have enough ZCO2`
      } else if (e.data && e.data.message && e.data.message.includes('ERC20: insufficient allowance')) {
        await dispatch('tokenContract/approveAll', state.calcContractAddress, {root: true})
        await dispatch('lockTokens', amount)
      } else if (e.message && e.message.includes('429')) {
        throw 'The current server is very busy, please try again.'
      } else {
        throw e;
      }
    }

  },
  sleep(s) {
    return new Promise(resolve => setTimeout(resolve, s * 1000));
  }
};

const mutations = {
  setLockedTokenTx(state, _lockedTokenTx) {
    state.lockedTokenTx = _lockedTokenTx;
  },
  setLockedTokenBalance(state, _lockedTokenBalance) {
    state.lockedTokenBalance = _lockedTokenBalance;
  },
  setLockedTokenAbi(state, abi) {
    state.calcAbi = abi;
  },
  setLockedTokenAddress(state, address) {
    state.calcAddress = address;
  },
  setLockedTokenContract(state, _contract) {
    state.calcContract = _contract;
  },
  setLockedTokenContractAddress(state, _contractAddress) {
    state.calcContractAddress = _contractAddress;
  },
  setSigner(state, _signer) {
    state.calcSigner = _signer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
