import tokenContractAbi from "../../contracts/tokenContractAbi.json";
import {ethers} from "ethers";
import Config from "../../api/Config";

const state = {
  tokenTx: null,
  tokenBalance: null,
  calcAbi: null,
  calcAddress: null,
  calcContractAddress: null,
  calcContract: null,
  signer: null
};

const getters = {
  getTokenTx(state) {
    return state.tokenTx;
  },
  getTokenBalance(state) {
    return state.tokenBalance;
  },
  getTokenAbi(state) {
    return state.calcAbi;
  },
  getTokenAddress(state) {
    return state.calcAddress;
  },
  getTokenContract(state) {
    return state.calcContract;
  },
  getSigner(state) {
    return state.calcSigner;
  }
};

const actions = {
  async fetchTokenContract({dispatch, commit, rootState}) {

    let providerW3m = rootState.accounts.providerW3m;
    const provider = new ethers.providers.Web3Provider(providerW3m);
    const config = (await Config.getConfig()).data;
    // const provider = new ethers.providers.JsonRpcProvider(config.json_rpc_provider);
    let contractAddress = config.token_contract_address;
    const signer = provider.getSigner();
    // const signer = new ethers.Wallet(privateKey, provider);
    const contract = new ethers.Contract(
      contractAddress,
      tokenContractAbi,
      signer
    );

    commit("setTokenContract", contract);
    commit("setTokenContractAddress", contractAddress);
    commit("setSigner", signer);

    dispatch("updateBalance");
  },
  async claim({state}, tokenId) {
    console.log({state})
    console.log({tokenId})


    // console.log(' state.calcSigner.getAddress()', state.calcSigner.getAddress())
    // console.log('await state.calcSigner.getAddress()', await state.calcSigner.getAddress())
    const tx = await state.calcContract.claim(tokenId);

    await tx.wait()

    console.log({tx})
    console.log('ethers.utils.formatEther(balance)', ethers.utils.formatEther(tx.value))
    // commit("setTokenBalance", parseFloat(ethers.utils.formatEther(tx.value)));

    return {
      tx,
      totalCO2: parseFloat(ethers.utils.formatEther(tx.value))
    }
  },
  async getLastClaimDt({commit, state}, forestAddress) {
    console.log({state})
    console.log({forestAddress})


    // console.log(' state.calcSigner.getAddress()', state.calcSigner.getAddress())
    // console.log('await state.calcSigner.getAddress()', await state.calcSigner.getAddress())
    const forestLastClaimDt = await state.calcContract.forestLastClaimDt(await state.calcSigner.getAddress());
    console.log({forestLastClaimDt})
    console.log('ethers.utils.formatEther(balance)', ethers.utils.formatEther(forestLastClaimDt))
    commit("setTokenBalance", parseFloat(ethers.utils.formatEther(forestLastClaimDt)));

    return {
      forestLastClaimDt
    }
  },
  async approveAll({state}, address) {
    // console.log({state})
    // console.log({address})
    // const config = (await Config.getConfig()).data;
    // console.log({commit})
    // console.log({config})
    // console.log(' state.calcSigner.getAddress()', state.calcSigner.getAddress())
    // console.log('await state.calcSigner.getAddress()', await state.calcSigner.getAddress())

    const tx = await state.calcContract.approve(address, ethers.utils.parseEther(state.tokenBalance.toString()));
    await tx.wait();

    return tx
  },
  async updateBalance({commit, state}) {
    // console.log({state})
    // const config = (await Config.getConfig()).data;
    // console.log({commit})
    // console.log({config})
    // console.log(' state.calcSigner.getAddress()', state.calcSigner.getAddress())
    // console.log('await state.calcSigner.getAddress()', await state.calcSigner.getAddress())
    if (state.calcSigner) {
      const walletAddress = await state.calcSigner.getAddress();

      const balance = await state.calcContract.balanceOf(walletAddress);
      // console.log({balance})
      // console.log('ethers.utils.formatEther(balance)', ethers.utils.formatEther(balance))
      commit("setTokenBalance", parseFloat(ethers.utils.formatEther(balance)));

      return {
        balance
      }

    }
  }
};

const mutations = {
  setTokenTx(state, _tokenTx) {
    state.tokenTx = _tokenTx;
  },
  setTokenBalance(state, _tokenBalance) {
    state.tokenBalance = _tokenBalance;
  },
  setTokenAbi(state, abi) {
    state.calcAbi = abi;
  },
  setTokenAddress(state, address) {
    state.calcAddress = address;
  },
  setTokenContract(state, _contract) {
    state.calcContract = _contract;
  },
  setTokenContractAddress(state, _contractAddress) {
    state.calcContractAddress = _contractAddress;
  },
  setSigner(state, _signer) {
    state.calcSigner = _signer;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
