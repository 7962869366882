import User from "../../api/User"
import Login from "../../api/Login"
import TokenStorage from "../../utils/TokenStorage"
import TokenParser from "../../utils/TokenParser"
import router from "@/router";

const state = {
  activeAccount: null,
  isConnected: false
};

const getters = {
  getWeb2ActiveAccount(state) {
    return state.activeAccount;
  },

  isWeb2UserConnected(state) {
    return state.isConnected;
  }
};

const actions = {

  async initWeb2({commit}) {
    const token = TokenStorage.get()
    if (token) {
      return commit("checkTokenExpired", token);
    }
  },

  async connectAccount({commit}, user) {
    commit("setIsConnected", true);
    commit("setActiveAccount", user);
  },

  async disconnectAccount({commit}) {
    commit("disconnectAccount");
    commit("setIsConnected", false);
  }

};

const mutations = {

  async disconnectAccount(state) {
    state.activeAccount = null;
    state.isConnected = false;
    TokenStorage.remove()
    router.push('/dashboard')
  },

  setActiveAccount(state, user) {
    state.activeAccount = user;
  },

  setIsConnected(state, isConnected) {
    state.isConnected = isConnected;
  },

  /**
   * Check if token expired in order to don't make useless call to API to renew the token
   */
  checkTokenExpired(state, token) {
    const tokenParsed = new TokenParser(token)
    if (!tokenParsed.isExpired()) {

      User.getMe().then((response) => {
        state.isConnected = true
        state.activeAccount = response.data
      })
    } else {
      return Login.renewToken().then((response) => {
        // Save the renewed token if different
        if (token !== response.data.token) {
          TokenStorage.set(response.data.token)
          state.isConnected = true
          state.activeAccount = response.data.user
        }
      }).catch(() => {
        // TODO What to do here?
      })
    }
  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
