<template>
  <div>
    <b-navbar toggleable="lg" type="dark">
      <router-link to="/">
        <b-navbar-brand>
          <img alt="" src="@/assets/logo.png">
        </b-navbar-brand>
      </router-link>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown
            v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
            class="text-white">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              Forests
            </template>
            <b-dropdown-item href="/areas-list">Forest list</b-dropdown-item>
            <b-dropdown-item href="/areas-map">Forest map</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item
            v-if="!isWeb2UserConnected || (getWeb2ActiveAccount && getWeb2ActiveAccount.role.name !== 'Administrator')">
            <router-link to="/areas-map">Forests map</router-link>
          </b-nav-item>
          <b-nav-item
            v-if="getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Authenticated'">
            <router-link to="/areas-list">My forests</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link
              v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'"
              to="/tree-types">Tree types
            </router-link>
          </b-nav-item>
          <b-nav-item v-if="false && isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
            <router-link to="/trees">Trees</router-link>
          </b-nav-item>
          <b-nav-item
            v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
            <router-link to="/customers">Customers</router-link>
          </b-nav-item>
          <b-nav-item
            v-if="isWeb2UserConnected && getWeb2ActiveAccount && getWeb2ActiveAccount.role.name === 'Administrator'">
            <router-link to="/admin">Admin</router-link>
          </b-nav-item>
          <b-nav-item
            v-if="isWeb2UserConnected && getLockedTokenBalance != undefined">
            <router-link to="/retire-tokens">Retire CO2</router-link>
          </b-nav-item>
          <b-nav-item
            v-if="config && config.exchange_url" :href="config.exchange_url" target="_blank">Exchange
          </b-nav-item>
          <b-nav-item
            v-if="config && config.liquidity_url" :href="config.liquidity_url" target="_blank">Liquidity
          </b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">

          <b-button v-if="getWeb2ActiveAccount?.role.name === 'Administrator' && adminStats.balance != undefined" class="mr-2 my-2"
                    variant="primary">
            Available
            <b-badge style="min-width: 100px;" variant="light">
              <span>{{
                  new Intl.NumberFormat('de-DE', {
                    maximumFractionDigits: 0
                  }).format(roundNum(adminStats.balance)) + ' ZCO2'
                }}</span>
            </b-badge>
          </b-button>

          <b-button v-if="isUserConnected && getTokenBalance != undefined" class="mr-2 my-2"
                    variant="primary">
            <img alt="" class="d-inline mr-1" src="@/assets/img/MetaMask-white.png"
                 style="height: 20px" @click="addToMetamask('ZCO2')">
            Balance
            <b-badge style="min-width: 100px;" variant="light" v-on:mouseleave="showBalanceQty"
                     v-on:mouseover="showBalancePrice">
              <span v-if="balanceShow == 'price' && stats.co2Price">{{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(roundNum(this.getTokenBalance * stats.co2Price)) + ' €'
                }}</span>

              <span v-else>{{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(roundNum(this.getTokenBalance)) + ' ZCO2'
                }}</span>
            </b-badge>
          </b-button>
          <b-button v-if="isUserConnected && getLockedTokenBalance != undefined"
                    class="mr-2 my-2"
                    to="/retire-tokens" variant="primary">
            <img alt="" class="d-inline mr-1" src="@/assets/img/MetaMask-white.png"
                 style="height: 20px" @click="addToMetamask('RZCO2')">
            Retired
            <b-badge style="min-width: 100px;" variant="light" v-on:mouseleave="showLockedQty"
                     v-on:mouseover="showLockedPrice">
              <span v-if="lockedShow == 'price' && stats.co2Price">{{


                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(roundNum(this.getLockedTokenBalance * stats.co2Price)) + ' €'
                }}</span>

              <span v-else>{{
                  new Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(roundNum(this.getLockedTokenBalance)) + ' RZCO2'
                }}</span>


            </b-badge>


          </b-button>
          <b-nav-item v-if="!isUserConnected && !isWeb2UserConnected" right>
            <router-link to="/dashboard">
              <b-button variant="primary">
                Connect
              </b-button>
            </router-link>
          </b-nav-item>
          <b-dropdown v-else class="my-2" right text="Account">

            <b-dropdown-item v-if="isWeb2UserConnected" href="#" to="/profile">
              Profile
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-item v-if="!isUserConnected && !isWeb2UserConnected" href="#" to="/dashboard">Sign in
            </b-dropdown-item>
            <b-dropdown-header>Web2</b-dropdown-header>
            <b-dropdown-item v-if="isWeb2UserConnected" href="#" to="/dashboard"><strong>Email:</strong>
              {{ getWeb2ActiveAccount.email }}...
            </b-dropdown-item>
            <b-dropdown-item v-if="isWeb2UserConnected" href="#" @click="disconnectAccount">Disconnect</b-dropdown-item>
            <b-dropdown-item v-else href="#" to="/dashboard">Not logged in.</b-dropdown-item>

            <b-dropdown-divider/>
            <b-dropdown-header>Web3</b-dropdown-header>
            <b-dropdown-item v-if="isUserConnected" href="#" to="/dashboard"><strong>Chain:</strong> {{ getChainName }}
            </b-dropdown-item>
            <b-dropdown-item v-if="isUserConnected" href="#" to="/dashboard"><strong>Address:</strong>
              {{ getActiveAccount.substring(0, 7) }}...
            </b-dropdown-item>
            <b-dropdown-item v-if="isUserConnected" href="#" @click="disconnectWeb3Modal">Disconnect</b-dropdown-item>
            <b-dropdown-item v-else href="#" to="/dashboard">Not logged in.</b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Stats from "../../api/Stats";
import Config from "../../api/Config";
import {roundDown} from "../../utils/roundDown";

export default {
  name: 'Header',
  data() {
    return {
      balanceShow: null,
      lockedShow: null,
      config: null,
      stats: {},
      adminStats: {},
    }
  },
  created() {

    Config.getConfig()
      .then((config) => {
        this.config = config.data
      }).catch((error) => {
      console.log(error)
    })
    Stats.getAdminStats()
      .then((adminStats) => {
        this.adminStats = adminStats.data
      }).catch((error) => {
      console.log(error)
    })
    this.showLockedQty();
    this.showBalanceQty();

    setInterval(() => {
      this.updateBalance();
      this.updateLockedBalance();
    }, 5000)
  },
  computed: {
    ...mapGetters("tokenContract", ["getTokenBalance"]),
    ...mapGetters("lockedTokenContract", ["getLockedTokenBalance"]),
    ...mapGetters("user", ["isWeb2UserConnected", "getWeb2ActiveAccount"]),
    ...mapGetters("accounts", ["getChainName", "getActiveAccount", "isUserConnected", "getWeb3Modal"]),
  },

  methods: {
    ...mapActions("user", ["disconnectAccount"]),
    ...mapActions("accounts", ["disconnectWeb3Modal"]),
    ...mapActions("tokenContract", ["updateBalance"]),
    ...mapActions("lockedTokenContract", ["updateLockedBalance"]),
    roundNum(number, decimals) {
      return roundDown(number, decimals)
    },
    showBalancePrice() {
      this.balanceShow = 'price';
    },
    showBalanceQty() {
      this.balanceShow = 'balance';
    },
    showLockedPrice() {
      this.lockedShow = 'price';
    },
    showLockedQty() {
      this.lockedShow = 'balance';
    },
    async addToMetamask(token) {
      try {
        let options = {}
        switch (token) {
          case 'RZCO2':
            options = {
              address: this.config.locked_token_contract_address, // The address that the token is at.
              symbol: token, // A ticker symbol or shorthand, up to 5 chars.
              decimals: 18, // The number of decimals in the token
            };
            break
          default:
            options = {
              address: this.config.token_contract_address, // The address that the token is at.
              symbol: token, // A ticker symbol or shorthand, up to 5 chars.
              decimals: 18, // The number of decimals in the token
            };


        }

        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options,
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style scoped>

</style>
