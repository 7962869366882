/**
 * @returns {number}
 */
const unixTime = () => {
  return Math.floor(Date.now() / 1000)
}

// Consider in the future to set more customization using moment.js library
/**
 * @param date
 * @param locale
 * @returns {string}
 */
const dateFormat = (date, locale) => {
  const oDate = new Date(date)

  return oDate.toLocaleDateString(locale)
}

// Consider in the future to set more customization using moment.js library
/**
 * @param date
 * @param locale
 * @returns {string}
 */
const dateTimeFormat = (date, locale) => {
  const oDate = new Date(date)

  return oDate.toLocaleString(locale)
}

/**
 * @param numberMonth -> must be used with two chars
 * @param locale -> language
 * @param upperCaseFirstLetter -> default set to FALSE
 * @returns {*}
 */
const monthString = (numberMonth, locale, upperCaseFirstLetter = false) => {
  let monthString = stringDate(new Date('1970-' + numberMonth + '-01'), locale, {month: 'long'})

  if (upperCaseFirstLetter === true) {
    monthString = monthString.charAt(0).toUpperCase() + monthString.slice(1)
  }

  return monthString
}

/**
 * @param date
 * @param locale
 * @param options
 * @returns {string}
 */
const stringDate = (date, locale, options = {year: 'numeric', month: 'long', day: 'numeric'}) => {
  const oDate = new Date(date)
  return oDate.toLocaleDateString(locale, options)
}

export {unixTime, dateFormat, dateTimeFormat, monthString, stringDate}
