import Ajax from './Ajax.js'

const Config = {
  getConfigPrivateKey: () => {
    return Ajax.get(`/config/private-key`);
  },
  getConfig: () => {
    return Ajax.get('/config');
  },
  putConfig: (params) => {
    return Ajax.put('/config/' + params.id, params)
  },
  delConfig: (id) => {
    return Ajax.delete('/config/' + id)
  }
}

export default Config
